import styled from 'styled-components';
import VeneerRadioButtonGroup from '@veneer/core/dist/scripts/radio_group';
import { getColor } from '../../../../../../common/utils/colorSelector';

interface IPlanCardProps {
  program: string;
  isSelected: boolean;
  isHPX?: boolean;
}

export const PlanCard = styled.div<IPlanCardProps>`
  && {
    display: flex;
    flex-direction: column;
    width: 156.4px;
    min-width: 156.4px !important;
    min-height: 228px;
    border-radius: 8px;
    border: ${(props) => (props.isHPX ? '2px' : '1px')} solid
      ${(props) => (props.isSelected ? getColor(props.program) : '#dbdbdb')};
    margin-right: 8px;
    &:last-child {
      margin-right: 0;
    }
    background-color: #ffffff;
    transition: border 1s;
    cursor: pointer;
  }
`;

export const InfoContainer = styled.div`
  && {
    display: flex;
    flex-direction: column;
    padding: 16px 12px 0px 12px;
    height: 100%;
  }
`;

type ColorContainerProps = {
  program: string;
  isVisible?: boolean;
};
export const ColorContainer = styled.div<ColorContainerProps>`
  && {
    background-color: ${(props) =>
      props.isVisible ? getColor(props.program) : 'transparent'};
    opacity: ${(props) => (props.isVisible ? '1' : '0')};
    width: 100%;
    height: 16px;
    border-radius: 0px 0px 7px 7px;
    transition: background-color 1s, opacity 1s;
  }
`;

export const RadioButtonGroup = styled(VeneerRadioButtonGroup)`
  && {
    margin-top: 24px;
    margin-bottom: 12px;
    padding-left: 12px;
    border: none;
  }
`;

export const PlanTagContainer = styled.div`
  && {
    margin-bottom: auto;
  }
`;

export const TransparentTag = styled.div`
  && {
    @media (min-width: 769px) {
      width: 100%;
      height: 24px;
      background-color: transparent;
    }
  }
`;
