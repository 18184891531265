import React, { useEffect, useState } from 'react';
import { CardContentContainer } from '../CardContentContainer';
import {
  useGetText,
  usePlanSelectorV3State,
  useDispatch
} from '../../../hooks';
import { setSelectedPlanAction } from '../../../actions/setSelectedPlanAction';
import { TitleContainer, Title, ComboBoxContainer, Combobox } from './styles';
import { useWindowSize } from '../../../../../common/hooks/useWindowSize';

export const ComboBoxPlanSelector: React.FC = () => {
  const getText = useGetText();
  const dispatch = useDispatch();
  const windowSize = useWindowSize();
  const [key, setKey] = useState(0);

  const { plans, selectedPlan, trackClickEvent } = usePlanSelectorV3State();
  const items = plans.i_ink.map((plan) => ({
    value: plan.guid,
    label: `${getText(
      `plans_selector.common.plans_frequency.${plan.frequency}`
    )} (${plan.pages} ${getText('plans_selector.plan_card.pages_per_month')})`
  }));
  const onChange = (selectedOption) => {
    const selected = plans.i_ink.find((p) => p.guid == selectedOption.value);
    trackClickEvent(
      `plan-radio-button-i-ink-${selected.pages}`,
      'ii_plan_selector'
    );
    return dispatch(setSelectedPlanAction(selected));
  };

  useEffect(() => {
    setKey(key + 1);
  }, [windowSize]);

  return (
    <CardContentContainer
      dataTestId="combo-box"
      isComboBoxSection
      applyTopRightBorder
      leftContent={
        <TitleContainer>
          <Title>{getText('plans_selector.current_plan_selection')}</Title>
        </TitleContainer>
      }
      rightContent={
        <ComboBoxContainer>
          <Combobox
            key={key}
            data-testid="plans-selector-v3-combobox"
            clearIcon={false}
            options={items}
            value={[selectedPlan.guid]}
            onChange={onChange}
          />
        </ComboBoxContainer>
      }
    />
  );
};
